
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PrimengModule } from '../primeNG/primeng.module';
import { MenuComponent } from '../shared/menu/menu.component';
import { ManageComponent } from './resoluciones/manage.component';
import { MinipeliComponent } from './peliculas/minipeli/minipeli.component';
import { TopPaisComponent } from './peliculas/top-pais/top-pais.component';
import { SelectorYearComponent } from './utilidades/selector-year.component';
import { SegundosTiempoPipe } from './utilidades/segundos-tiempo.pipe';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [MenuComponent, ManageComponent, MinipeliComponent, TopPaisComponent, SelectorYearComponent, SegundosTiempoPipe, FooterComponent],
  imports: [
    PrimengModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    MenuComponent, ManageComponent, MinipeliComponent, TopPaisComponent, SelectorYearComponent, SegundosTiempoPipe, FooterComponent
  ]
})
export class SharedModule { }
