import { CalendarEvent } from 'angular-calendar';
//import { FechasSalida } from './../../models/pelicula.model';
import { createReducer, on } from '@ngrx/store';

import * as fsActions from '../actions/fechas.actions';

export interface State {
  fechassalida: CalendarEvent[];
}

export const initialState: State = {
  fechassalida: [],
};

const _fsReducer = createReducer(
  initialState,

  on(fsActions.cargaFS, (state, { fechassalida }) => ({
    ...state,
    fechassalida: [...fechassalida],
  })),
  on(fsActions.limpiaFS, (state) => ({ ...state, fechassalida: [] }))
);

export function fsReducer(state, action) {
  return _fsReducer(state, action);
}
