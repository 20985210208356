// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDNCXZv90cSXO_-oeo2Wx7UMiAW4baP2Hs",
  authDomain: "controlpeliculas-abfb1.firebaseapp.com",
  projectId: "controlpeliculas-abfb1",
  storageBucket: "controlpeliculas-abfb1.appspot.com",
  messagingSenderId: "23793673668",
  appId: "1:23793673668:web:af4fd97e831431a3f9b091",
  measurementId: "G-3MT0W1K9BB"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
