import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { Datopelicula } from 'src/app/models/pelicula.model';
import * as _ from 'lodash';

interface datoPeli {
  titulo: string;
  total: number;
}

@Component({
  selector: 'app-top-pais',
  templateUrl: './top-pais.component.html',
  styleUrls: ['./top-pais.component.scss'],
})
export class TopPaisComponent implements OnInit, OnChanges {
  @Input() fecha: Date;
  @Input() elyear: string;
  @Input() pais: string;
  @Input() code: string;
  @Input('cantidad') cuantos: number;
  reproduccionesperiodo: number = null;
  display: boolean = false;

  campofechames: string;
  year: number;
  lapeliculas$: Observable<Datopelicula[]>;
  muestraPeli$: Observable<datoPeli[]>;
  todaslaspelis$: Observable<datoPeli[]>;

  constructor(private store: Store<AppState>) {}
  showDialog() {
    this.display = true;
  }

  ngOnInit(): void {
    /* const mes = ("0" + (this.fecha.getMonth() + 1)).slice(-2);
    this.campofechames = this.fecha.getFullYear() + mes;
    this.year = this.fecha.getFullYear() */

    this.lapeliculas$ = this.store.select('peliculaspublicadas').pipe(
      map((res) => [...res.peliculas]),
      map((res) => _.orderBy(res, ['total'], ['desc']))
      //tap( res => console.info('INIT top-pais', res) )
    );
    //this.muestraPeli$ = this.peliculas$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    //console.log ("EL PAIIIIIIIIS", this.pais, this.elyear, this.fecha, this.cuantos)
    //console.info ('FEcha cooming', this.fecha, this.year)
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (this.elyear) {
      //console.log ( this.elyear, ' es un Knijo número')
      //this.mesyearselected = null
      if (this.elyear === 'ALL') {
        //console.log ('ALL o sea ALLEZ!!')
        let y = this.store.select('peliculaspublicadas').pipe(
          map((res) => [...res.peliculas]),
          map((res) => _.orderBy(res, ['total'], ['desc']))
          //tap( res => console.info('INIT top-pais', res) )
        );
        this.muestraPeli$ = y.pipe(
          map((res) => _.orderBy(res, ['total'], ['desc'])),
          map((res) => res.filter((pelicula) => pelicula.pais === this.pais)),
          tap((res) => {
            this.reproduccionesperiodo = _.sumBy(res, 'total');
          }),
          map((res) =>
            res.map((pelicula) => ({
              activa: pelicula.activa,
              titulo: pelicula.titulo,
              total: pelicula.total,
            }))
          ),
          //res => res.map(pelicula => ({
          //this.todaslaspelis$ = of(res) ),
          tap(console.log),
          tap((res) => (this.todaslaspelis$ = of(res))),
          map((data) => data.slice(0, this.cuantos))
        );
      } else {
        let y = this.store.select('peliculaspublicadas').pipe(
          map((res) => [...res.peliculas]),
          map((res) => _.orderBy(res, ['total'], ['desc']))
          //tap( res => console.info('INIT top-pais', res) )
        );

        this.muestraPeli$ = y.pipe(
          map((res) => res.filter((pelicula) => pelicula[this.elyear] > 0)),
          map((res) => _.orderBy(res, [this.elyear], ['desc'])),
          map((res) => res.filter((pelicula) => pelicula.pais === this.pais)),
          tap((res) => {
            //console.log ('llear',res[0])
            this.reproduccionesperiodo = _.sumBy(res, `${this.elyear}`);
          }),
          map((res) =>
            res.map((pelicula) => ({
              activa: pelicula.activa,
              titulo: pelicula.titulo,
              total: pelicula[`${this.elyear}`],
            }))
          ),

          tap((res) => (this.todaslaspelis$ = of(res))),
          map((data) => data.slice(0, this.cuantos))
        );
      }
    } else {
      const mes = ('0' + (this.fecha.getMonth() + 1)).slice(-2);
      this.campofechames = this.fecha.getFullYear()+'_' + mes;
      this.year = this.fecha.getFullYear();

      this.lapeliculas$ = this.store.select('peliculaspublicadas').pipe(
        map((res) => [...res.peliculas]),
        map((res) => _.orderBy(res, ['total'], ['desc']))
      );
      //const campo = 'visitas[this.year][this.campofechames]';
      this.muestraPeli$ = this.lapeliculas$.pipe(
        map((res) =>
          res.filter(
            (pelicula) => pelicula.visitas[this.year][this.campofechames] > 0
          )
        ),
        //map( (res) => _.orderBy(res, ['visitas.2020.202003'], ['desc']) )
        //map( (res) => _.orderBy(res, ['visitas['+this.year+']['+this.campofechames+']'], ['desc']) ),
        map((res) =>
          _.orderBy(
            res,
            ['visitas[' + this.year + '][' + this.campofechames + ']'],
            ['desc']
          )
        ),
        map((res) => res.filter((pelicula) => pelicula.pais === this.pais)),
        tap((res) => {
          //console.log ('MESSIES',res[0])
          this.reproduccionesperiodo = _.sumBy(
            res,
            `visitas[${this.year}][${this.campofechames}]`
          );
        }),
        map((res) =>
          res.map((pelicula) => ({
            activa: pelicula.activa,
            titulo: pelicula.titulo,
            total: pelicula.visitas[this.year][this.campofechames],
          }))
        ),
        tap((res) => (this.todaslaspelis$ = of(res))),
        map((data) => data.slice(0, this.cuantos))
        //tap( res => { console.log('REZ', res) } ),
      );
    }
  }
}
