import { ActionReducerMap } from '@ngrx/store';
import * as ui from './shared/reducers/ui.reducer';
import * as filmes from './peliculas/reducers/pelicula.reducer';
import * as auth from './auth/reducers/auth.reducer';
import * as resoluciones from './shared/reducers/resoluciones.reducer';
import * as publicadas from './peliculas/locales/locales.reducer';
import * as metricas from './metricas/reducers/metricas.reducer';
import * as geovisitas from './metricas/reducers/geovisitas.reducer';
import * as metricapps from './shared/reducers/apps.reducer';
import * as fechassalida from './fechasalida/reducers/fechas.reducer';
import * as redes from './redes/reducers/redes.reducer'

export interface AppState {
  ui: ui.State;
  /* peliculas: filmes.State, */
  auth: auth.State;
  resoluciones: resoluciones.State;
  peliculaspublicadas: publicadas.State;
  metricas: metricas.State;
  geovisitas: geovisitas.State;
  metricapps: metricapps.State;
  fechassalida: fechassalida.State;
  redes: redes.State
}

export const appReducers: ActionReducerMap<AppState> = {
  ui: ui.uiReducer,
  /* peliculas: filmes.peliculaReducer, */
  auth: auth.authReducer,
  resoluciones: resoluciones.resReducer,
  peliculaspublicadas: publicadas.publicadasReducer,
  metricas: metricas.metReducer,
  geovisitas: geovisitas.geoVisReducer,
  metricapps: metricapps.metricApsReducer,
  fechassalida: fechassalida.fsReducer,
  redes: redes.redesReducer
};
