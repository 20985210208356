import { createReducer, on } from '@ngrx/store';
import { AppMetrica } from 'src/app/models/android-app-model';

import * as metricasAppsActions from '../actions/apps.actions';

export interface State {
  metricapps: AppMetrica[];
}

export const initialState: State = {
  metricapps: []
}

const _metricApsReducer = createReducer(initialState,

  on(metricasAppsActions.cargaMetricasApps, (state, {metricapps}) => ({ ...state, metricapps: [ ...metricapps ]})),
  on(metricasAppsActions.limpiaMetricasApps, (state) => ({  ...state, metricapps: []})),

);

export function metricApsReducer(state, action) {
    return _metricApsReducer(state, action);
}
