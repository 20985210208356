import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { Resoluciones } from 'src/app/models/pelicula.model';
import { ResolucionesService } from '../resoluciones.service';

@Component({
  selector: 'Resoluciones',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.scss']
})
export class ManageComponent implements OnInit {
  resoluciones: Observable<Resoluciones[]>;
  @Output() cierraDialog = new EventEmitter<boolean>();
  formaResoluciones: FormGroup;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private _res: ResolucionesService
    ) { }

  ngOnInit(): void {
    this.resoluciones = this.store.select('resoluciones').pipe(
      map( (res) => res.resoluciones )
    )
    this.creaFormulario();
  }
  creaFormulario(){
    this.formaResoluciones = this.fb.group({
      ancho: [, [Validators.pattern("^[0-9]*$"),]],
      alto: [, [Validators.pattern("^[0-9]*$"),]]
    })
  }
  cierra(){
    this.cierraDialog.emit(false)
  }
  guardaResolucion(){
    if(this.formaResoluciones.invalid){
      return
    }
    const { alto, ancho } = this.formaResoluciones.value;
    const resolucion: Resoluciones = {
      alto, ancho
    }
    this._res.guardaResolucion(  resolucion ).then( (res) =>{
      console.log ( res.id)
    })

  }

}
