export class Usuario {

  /* static fromFirebase( {email, uid, nombre}){
    return new Usuario( uid, nombre, email );
  } */

  static fromFirebase( { email, uid, nombre, admin } ) {
    return new Usuario( uid, nombre, email, admin );
}

  constructor(
    public uid: string,
    public nombre: string,
    public email: string,
    public admin?: boolean,

  ){}
}
