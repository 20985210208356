import { createReducer, on } from '@ngrx/store';
import { Datopelicula } from 'src/app/models/pelicula.model';
import * as peliculasPublicadasActions from './locales.actions';

export interface State {
    peliculas: Datopelicula[];
}

export const initialState: State = {
  peliculas: []
}

const _publicadasReducer = createReducer(initialState,

  on(peliculasPublicadasActions.setPeliculas, (state, {peliculas}) => ({ ...state, peliculas: [ ...peliculas ]})),
  on(peliculasPublicadasActions.unSetPeliculas, (state) => ({  ...state, peliculas: []})),

);

export function publicadasReducer(state, action) {
    return _publicadasReducer(state, action);
}
