import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [

  { path: 'acceso', loadChildren: () => import( './auth/auth.module' ).then( login => login.AuthModule ) },
  { path: 'inicio', loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioModule)},
  { path: 'peliculas', loadChildren: () => import('./peliculas/peliculas.module').then( m => m.PeliculasModule  ), canLoad: [
    AuthGuard
  ] },
  { path: 'metricas', loadChildren: () => import( './metricas/metricas.module' ).then( metricas => metricas.MetricasModule ) },

  { path: 'personas', loadChildren: () => import('./personas/personas.module').then(m => m.PersonasModule) },

  { path: 'fechasalida', loadChildren: () => import('./fechasalida/fechasalida.module').then(m => m.FechasalidaModule) },

  { path: 'redes', loadChildren: () => import('./redes/redes.module').then(m => m.RedesModule) },
  { path: '**', redirectTo:  'peliculas' }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot( routes )
  ]
})
export class AppRoutingModule { }
