import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducers';
import { Datopelicula } from 'src/app/models/pelicula.model';

@Component({
  selector: 'minipeli',
  templateUrl: './minipeli.component.html',
  styleUrls: ['./minipeli.component.scss']
})
export class MinipeliComponent implements OnInit {
@Input() peliId: number;
@Input() entrada: boolean;
pelicula$: Observable<Datopelicula>;
  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.pelicula$ = this.store.select('peliculaspublicadas')
    .pipe(
      map( (res)=> [... res.peliculas] ),
      map( (res)=> res.find( peli => peli.id === this.peliId ) ),

      //map( res => res.filter( peli => peli.diff === 0 ) )
    )
  }

}
