<!-- <pre>
  PEL: {{ (lapeliculas$ | async).length }}
   MUESTRA: {{ (muestraPeli$ | async).length }}
</pre> -->

<ng-container *ngIf="muestraPeli$  | async as peliculas">
  <p-dialog [header]="pais" [(visible)]="display" [style]="{width: '50vw'}">
    <p-card >
      <p-header>
        <div class="p-pt-4 p-pl-4 labeldato500">
          <img [src]="'assets/flags/'+code +'.svg' " alt="" *ngIf="code" class="imagopais p-shadow-10">
          {{ pais }} </div>

      </p-header>
      <p-table [value]="todaslaspelis$ | async" styleClass="p-datatable-striped p-datatable-sm">
        <ng-template pTemplate="body" let-product let-i="rowIndex">
            <tr [class]="product.activa ? 'filmActivo' : 'filmInactivo' ">
              <td width="32px"> {{ i +1  }} </td>
              <td
              [class]=" i < 1 ? 'labeldato500' : 'labeldato100'"


              >{{product.titulo}}</td>

                <td
                [class]=" i < 1 ? 'numerohard' : 'numerosoft'"
                class="p-text-right">
                {{ product.total | number }}

                </td>

            </tr>
        </ng-template>
      </p-table>
      <p-footer>
        <span class="numerohard">{{ (todaslaspelis$ | async).length }}</span> películas fueron reproducidas <span class="numerohard"> {{ reproduccionesperiodo | number }}  </ span> veces.
      </p-footer>
    </p-card>


  </p-dialog>


  <!-- <ng-container *ngIf="reproduccionesperiodo > 0"> -->
<p-card >
  <p-header>
    <div class="p-pt-4 p-pl-4 labeldato500">
      <img [src]="'assets/flags/'+code +'.svg' " alt="" *ngIf="code" class="imagopais p-shadow-10">
      {{ pais }}
      <button pButton
      styleClass="p-button-sm"
      pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-outlined" (click)="showDialog()" ></button>
    </div>



  </p-header>
  <p-table [value]="peliculas" styleClass="p-datatable-striped p-datatable-sm">
    <ng-template pTemplate="body" let-product let-i="rowIndex">
        <tr [class]="product.activa ? 'filmActivo' : 'filmInactivo' ">
          <td width="32px"> {{ i +1  }} </td>
          <td
          [class]=" i < 1 ? 'labeldato500' : 'labeldato100'"

          >{{product.titulo}}</td>
            <!-- <td width="100px"
            [class]=" i < 1 ? 'numerohard' : 'numerosoft'"
            class="p-text-right"
            *ngIf="!elyear">{{product.visitas[year][campofechames] | number }}</td>
            <td width="100px"
            [class]=" i < 1 ? 'numerohard' : 'numerosoft'"
            class="p-text-right"
            *ngIf="elyear">{{product[elyear] | number }}</td>
            <td width="100px"
            [class]=" i < 1 ? 'numerohard' : 'numerosoft'"
            class="p-text-right"
            *ngIf="elyear && elyear==='ALL'  ">{{product.total | number }}</td> -->
            <td
            [class]=" i < 1 ? 'numerohard' : 'numerosoft'"
            class="p-text-right">
            {{ product.total | number }}

            </td>

        </tr>
    </ng-template>
  </p-table>
  <p-footer>
    <span class="numerohard">{{ (todaslaspelis$ | async).length }}</span> películas fueron reproducidas <span class="numerohard"> {{ reproduccionesperiodo | number }}  </ span> veces.
  </p-footer>
</p-card>

<!-- </ng-container> -->
</ng-container>




