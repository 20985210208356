import { createReducer, on } from '@ngrx/store';

import * as geovisitasActions from '../actions/geovisitas.actions';
import { Geovisitas } from '../metricas.model';


export interface State {
    geovisitas: Geovisitas[];
}

export const initialState: State = {
  geovisitas: []
}

const _geoVisReducer = createReducer(initialState,

  on(geovisitasActions.cargaGeo, (state, {geovisitas}) => ({ ...state, geovisitas: [ ...geovisitas ]})),
  on(geovisitasActions.limpiaGeo, (state) => ({  ...state, geovisitas: []})),

);

export function geoVisReducer(state, action) {
    return _geoVisReducer(state, action);
}
