<div class="lefuter">
  <div class="p-grid p-jc-center">
    <div class="p-col">
      <img src="assets/logos/_bolivia.png" alt="">
      <img src="assets/flags/bo.svg" alt="">
    </div>
    <div class="p-col">
      <img src="assets/logos/_colombia.png" alt="">
      <img src="assets/flags/co.svg" alt="">
    </div>
    <div class="p-col">
      <img src="assets/logos/_ecuador.png" alt="">
      <img src="assets/flags/ec.svg" alt="">
    </div>
    <div class="p-col">
      <img src="assets/logos/_mexico.png" alt="">
      <img src="assets/flags/mx.svg" alt="">
    </div>
    <div class="p-col">
      <img src="assets/logos/_peru.png" alt="">
      <img src="assets/flags/pe.svg" alt="">
    </div>
    <div class="p-col">
      <img src="assets/logos/_uruguay.png" alt="">
      <img src="assets/flags/uy.svg" alt="">
    </div>


  </div>
  <!-- <div class="p-grid p-jc-center">
    <div class="p-col flag">

    </div>
    <div class="p-col flag">

    </div>
    <div class="p-col flag">

    </div>
    <div class="p-col flag">

    </div>
    <div class="p-col flag">

    </div>
    <div class="p-col flag">

    </div>
  </div> -->
</div>