<pre>
  {{ ( resoluciones | async ) | json  }}
</pre>

<form [formGroup]="formaResoluciones" (ngSubmit)="guardaResolucion()">
  <div class="p-field p-grid">
    <label for="ancho" class="p-col-fixed" style="width:100px">ancho</label>
    <div class="p-col">
      <p-inputNumber
      inputId="integeronly"
      id="ancho"
      formControlName="ancho"
      ></p-inputNumber>
    </div>
  </div>

  <div class="p-field p-grid">
    <label for="alto" class="p-col-fixed" style="width:100px">Alto</label>
    <div class="p-col">
      <p-inputNumber
      inputId="integeronly"
      id="alto"
      formControlName="alto"
      ></p-inputNumber>
    </div>
  </div>






  <div class="p-field p-grid">
    <div class="p-col">
      <button pButton type="submit" label="Guardar película" class="p-button-danger p-button-lg filmes100" [disabled]="formaResoluciones.invalid"></button>
    </div>
  </div>


</form>



<button pButton type="submit" label="Cerrar" class="p-button-danger p-button-lg filmes100" (click)="cierra()"></button>
