import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { AppState } from 'src/app/app.reducers';
import { AuthService } from 'src/app/auth/auth.service';
import { Pelicula } from 'src/app/models/pelicula.model';
import * as peliculasActions from 'src/app/peliculas/actions/peliculas.actions';
import { DataFilmesService } from 'src/app/peliculas/data-filmes.service';
import * as peliculasPublicadasActions from 'src/app/peliculas/locales/locales.actions';
import * as resolucionesActions from '../actions/resoluciones.actions';
import * as metricasActions from '../../metricas/actions/metricas.actions';
import * as geovisitasActions from '../../metricas/actions/geovisitas.actions';
import { Observable, Subscription } from 'rxjs';
import { Usuario } from 'src/app/models/usuario.model';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'MainMenu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  isAuth: boolean;
  itemsUser: MenuItem[];
  itemsAdmin: MenuItem[];
  usuario$: Observable<Usuario>;
  admin: boolean;
  suscribeuser: Subscription;
  correo: string;

  constructor(
    private store: Store<AppState>,
    private _dataFilmes: DataFilmesService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.auth.isAuth().subscribe((res) => {
      this.isAuth = res;
      if (this.isAuth) {
        this.suscribeuser = this.store.select('auth').subscribe((res) => {
          if (res.user) {
            this.correo = res.user.email;
            this.admin = res.user.admin;
            if (!this.admin) {
              this.router.navigate(['/metricas/mes']);
            }
          }
        });
      }
    });

    this.itemsUser = [
      { label: 'Películas', routerLink: '/peliculas/topmes' },
      { label: 'Estadísticas', routerLink: '/metricas/mes' },
      { label: 'Publicaciones', routerLink: '/peliculas/publicaciones' },
      { label: 'Salida de películas (beta)', routerLink: '/fechasalida' },
      { label: 'Cerrar', command: () => this.logout() },
    ];
    this.itemsAdmin = [
      { label: 'Personas', routerLink: 'personas' },
      { label: 'Películas', routerLink: 'peliculas' },
      { label: 'Film OUT', routerLink: '/fechasalida' },
      { label: 'Lista', routerLink: '/peliculas/lista' },
      { label: 'Nueva', routerLink: '/peliculas/crea' },
      { label: 'Publicadas', routerLink: '/peliculas/json' },
      { label: 'Fecha', routerLink: '/peliculas/fecha' },
      { label: 'Publicaciones', routerLink: '/peliculas/publicaciones' },
      { label: 'Películas', routerLink: '/peliculas/topmes' },
      { label: 'Métricas', routerLink: '/metricas' },
      { label: 'Estadísticas', routerLink: '/metricas/mes' },
      { label: 'Redes', routerLink: '/redes' },
      { label: 'Cerrar ', command: () => this.logout() },
    ];
  }
  logout() {
    this.auth.logout().then(() => {
      this.store.dispatch(peliculasActions.unSetPeliculas());
      this.store.dispatch(resolucionesActions.limpiaRes());
      this.store.dispatch(peliculasPublicadasActions.unSetPeliculas());
      this.store.dispatch(metricasActions.limpiaMet());
      this.store.dispatch(geovisitasActions.limpiaGeo());
      this.router.navigate(['/acceso/login']);
    });
  }
  esAdmin(): boolean {
    return this.admin;
  }
  ngOnDestroy() {
    this.suscribeuser.unsubscribe();
  }
}
