import { Component, EventEmitter, OnInit, Output } from '@angular/core';


@Component({
  selector: 'botones-year',
  templateUrl: './selector-year.component.html',
  styleUrls: ['./selector-year.component.scss']
})
export class SelectorYearComponent implements OnInit {
@Output() filtroYear = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  cambiaYear(year: string){
    this.filtroYear.emit(year);

  }

}
