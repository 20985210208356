<div class="mainApp">
  <MainMenu *ngIf="isAuth"></MainMenu>
  <router-outlet></router-outlet>
  <FooterRetina *ngIf="isAuth"></FooterRetina>
</div>

<!-- <span class="p-float-label p-input-filled">
  <input id="elTitulo" type="text" [(ngModel)]="title" pInputText>
  <label for="elTitulo">Título</label>
  </span>


  <hr>
<button pButton type="button" [label]="title" ></button>
<p-button label="Click" ></p-button>


<button pButton type="button" label="Sono un bottonne" class="p-shadow-10 p-m-6"></button>
<hr>

<div class="p-d-inline">Displayed as inline.</div>
<div class="p-d-flex">Displayed as a flexbox container.</div>
<div class="p-d-block p-d-lg-inline">Inline for larger screens and block for others.</div>
<div class="p-d-md-none">Visible on a Small Screen</div>
<div class="p-d-none p-d-md-inline-flex">Hidden on a Small Screen</div>
<div class="p-d-none p-print-block">Only visible when printed.</div>
<div class="p-d-block p-print-none">Not available for printing.</div>

<p-accordion>
  <p-accordionTab header="Header 1" [selected]="true">
     Content 1
  </p-accordionTab>
  <p-accordionTab header="Header 2">
      Content 2
  </p-accordionTab>
  <p-accordionTab header="Header 3">
      Content 3
  </p-accordionTab>
  

</p-accordion> -->

