import { createReducer, on } from '@ngrx/store';
import { Resoluciones } from 'src/app/models/pelicula.model';
import * as resolucionesActions from '../actions/resoluciones.actions';

export interface State {
    resoluciones: Resoluciones[];
}

export const initialState: State = {
  resoluciones: []
}

const _resReducer = createReducer(initialState,

  on(resolucionesActions.cargaRes, (state, {resoluciones}) => ({ ...state, resoluciones: [ ...resoluciones ]})),
  on(resolucionesActions.limpiaRes, (state) => ({  ...state, resoluciones: []})),

);

export function resReducer(state, action) {
    return _resReducer(state, action);
}
