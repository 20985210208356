<p-menubar [model]=" admin ? itemsAdmin : itemsUser " *ngIf="isAuth">
  <ng-template pTemplate="start">
    <div class="logo">
      <img src="assets/retina-logo-360.png" height="40" class="p-mr-2">
    </div>

  </ng-template>

  <ng-template pTemplate="end">
    <!-- <input type="text" pInputText placeholder="Buscar"> -->

    {{ correo }} - v: 2.01
    <!-- <img src="assets/logoretina-bn-160-130.png" height="40" class="p-mr-2"> -->


  </ng-template>
</p-menubar>