import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
//import { GoogleSheetsDbService } from 'ng-google-sheets-db';
import { map } from 'rxjs/operators';
import { AppState } from '../app.reducers';
import { AppMetrica } from '../models/android-app-model';
import { DataFilmesService } from '../peliculas/data-filmes.service';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AppsService {
  AndroidData = {
    fecha: 'Date',
    instaladia: 'Daily Device Installs',
    userinstaladia: 'Daily User Installs',
    useruninstaladia: 'Daily User Uninstalls',
    activas: 'Active Device Installs',
    instalaciones: 'Install events',
    updates: 'Update events',
    desinstalaciones: 'Uninstall events',
  };
  IosData = {
    fecha: 'Fecha',
    instalaciones: 'instalaciones',
    desinstalaciones: 'desinstalaciones',
  };
  constructor(
    private _data: DataFilmesService,
    //private googlesheets: GoogleSheetsDbService,
    private store: Store<AppState>,
    private HTTP: HttpClient
  ) {}

  initMetricasAndroidListener() {
    //return this.googlesheets.get<AppMetrica>(this._data.getGoogleSheetId(), 5, this.AndroidData)
    return this.HTTP.get<any>('https://script.google.com/macros/s/AKfycbyC2h0B9j8q68GUgy7xMA3IIc-JYC6ECplTcwzf3c3oM5pbQvWg4W5y3z9rUi8Tm4Ve/exec')
      .pipe(
        map((res) =>
          res.appdata.android.map((dato) => ({
            //...dato,
            fecha: moment(dato.fecha).toDate(),
            mes: +moment(dato.fecha).format('MM'),
            year: +moment(dato.fecha).format('YYYY'),
            instalaciones: +dato.instalaciones,
            desinstalaciones: +dato.desinstalaciones,
            origen: 'ANDROID',
          }))
        )
      );
  }
  initMetricasIOSListener() {
    //return this.googlesheets.get<AppMetrica>(this._data.getGoogleSheetId(), 6, this.IosData)
    return this.HTTP.get<any>('https://script.google.com/macros/s/AKfycbyC2h0B9j8q68GUgy7xMA3IIc-JYC6ECplTcwzf3c3oM5pbQvWg4W5y3z9rUi8Tm4Ve/exec')
      .pipe(
        map((res) =>
          res.appdata.ios.map((dato) => ({
            //...dato,
            fecha: moment(dato.fecha).toDate(),
            mes: +moment(dato.fecha).format('MM'),
            year: +moment(dato.fecha).format('YYYY'),
            instalaciones: +dato.instalaciones,
            desinstalaciones: +dato.desinstalaciones,
            origen: 'IOS',
          }))
        )
      );
  }
  cargaAll() {
    return forkJoin([
      this.initMetricasAndroidListener(),
      this.initMetricasIOSListener(),
    ]).pipe(
      map((results) => {
        let finalResult = [];
        results.forEach((result) => {
          finalResult = finalResult.concat(result);
        });
        return finalResult;
      })
    );
  }

  metricas(fecha: Date, year: number = null): Observable<any> {
    let metricas$: Observable<AppMetrica[]>;
    metricas$ = this.store
      .select('metricapps')
      .pipe(map((res) => res.metricapps));

    if (fecha) {
      const mes = +moment(fecha).format('MM');
      const leyear = +moment(fecha).format('YYYY');
      metricas$ = metricas$.pipe(
        map((res) =>
          res.filter((dato) => dato.mes === mes && dato.year === leyear)
        )
      );
    } else {
      if (year === 0) {
        //return `ACCUMULADO`
      } else if (year >= 2020) {
        metricas$ = metricas$.pipe(
          map((res) => res.filter((dato) => dato.year === year))
        );
        //return `ACCUMULADO AÑO ${year}`
      } else {
        return null;
      }
    }
    return metricas$.pipe(
      map((res) => {
        return this.salidaMetricas(res);
      })
    );
  }

  metricasAnteriores(fecha: Date, year: number = null): Observable<any> {
    let metricas$: Observable<AppMetrica[]>;
    metricas$ = this.store
      .select('metricapps')
      .pipe(map((res) => res.metricapps));

    if (fecha) {
      if (fecha < new Date('2020-04-01T00:00:00.000+00:00')) {
        return null;
      }
      let mes = +moment(fecha).format('MM') - 1;
      let leyear = +moment(fecha).format('YYYY');
      if (mes === 0) {
        mes = 12;
        leyear = leyear - 1;
      }
      console.log('BUSCA Métricas de MES', mes, 'YEAR', leyear);

      metricas$ = metricas$.pipe(
        map((res) =>
          res.filter((dato) => dato.mes === mes && dato.year === leyear)
        )
      );
    } else {
      if (year === 0) {
        //return `ACCUMULADO`
        return null;
      } else if (year > 2020) {
        metricas$ = metricas$.pipe(
          map((res) => res.filter((dato) => dato.year === year - 1))
        );
        //return `ACCUMULADO AÑO ${year}`
      } else {
        return null;
      }
    }
    return metricas$.pipe(
      map((res) => {
        return this.salidaMetricas(res);
      })
    );
  }

  salidaMetricas(res) {
    const android = res.filter((data) => data.origen === 'ANDROID');
    const ios = res.filter((data) => data.origen === 'IOS');
    let salida = {
      insAND: _.sumBy(android, 'instalaciones'),
      uninsAND: _.sumBy(android, 'desinstalaciones'),
      insIOS: _.sumBy(ios, 'instalaciones'),
      uninsIOS: _.sumBy(ios, 'desinstalaciones'),
      activAND:
        _.sumBy(android, 'instalaciones') -
        _.sumBy(android, 'desinstalaciones'),
      activIOS:
        _.sumBy(ios, 'instalaciones') - _.sumBy(ios, 'desinstalaciones'),
      activas:
        _.sumBy(android, 'instalaciones') -
        _.sumBy(android, 'desinstalaciones') +
        (_.sumBy(ios, 'instalaciones') - _.sumBy(ios, 'desinstalaciones')),
      totalins:
        _.sumBy(android, 'instalaciones') + _.sumBy(ios, 'instalaciones'),
      totalunins:
        _.sumBy(android, 'desinstalaciones') + _.sumBy(ios, 'desinstalaciones'),
    };
    return salida;
  }
}
