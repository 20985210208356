import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import { GoogleSheetsDbService } from 'ng-google-sheets-db';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DataFilmesService } from '../peliculas/data-filmes.service';
import { Geovisitas, Metricas, Redes } from './metricas.model';

@Injectable({
  providedIn: 'root'
})
export class MetricasService {

redes = {
  mes: 'mes',
  year: 'year',
  facebook: 'facebook',
  twitter: 'twitter', 
  instagram: 'instagram',
  linktree: 'linktree',
  youtube: 'youtube',
  articulos: 'articulos',
  alianzas: 'alianzas'
}

  geovisitas = {
    pais: 'pais',
    sesiones: 'sesiones',
    mes: 'mes',
    year: 'year'
  }

  metricas = {
    mes: 'mes',
    year: "year",
    sesiones: "sesiones",
    usuarios_analytics: "usuarios_analytics",
    duracionmedia: "duracion_media",
    rebote: "rebote",
    nuevas_sesiones: "nuevas_sesiones",
    usuarioswp: "usuarios_wp",
    edad: {
      edadg1: "edad_g1",
      edadg2: "edad_g2",
      edadg3: "edad_g3",
      edadg4: "edad_g4",
      edadg5: "edad_g5",
      edadg6: "edad_g6",
    },
    genero: {
      h: "h",
      m: "m",
    },
    tecnologia: {
      tecno_escritorio: "tecno_escritorio",
      tecno_movil: "tecno_movil",
      tecno_tablet: "tecno_tablet",
    },
    adquisicion: {
      adq_directa: "adq_directa",
      adq_organica: "adq_organica",
      adq_social: "adq_social",
      adq_referral: "adq_referral",
    },
    visitas_paginas: "visitas_paginas",
    reproducciones: "reproducciones",
    facebook: 'facebook',
    twitter: 'twitter', 
    instagram: 'instagram',
    linktree: 'linktree',
    youtube: 'youtube',
    articulos: 'articulos',
    alianzas: 'alianzas'
  }

  constructor(
    //private googlesheets: GoogleSheetsDbService,
    private _datos: DataFilmesService,
    private HTTP: HttpClient
  ) { }

  initGeovisitasListener(){
    return this.HTTP.get<any>('https://script.google.com/macros/s/AKfycbw0XX9elJSZ234DxYXQ3kEaFx5KeTE3fv7zsiTTTt42hGlYHRQF60HgwBvaWFLM_HMc/exec')
    //return this.googlesheets.get<Geovisitas>(this._datos.getGoogleSheetId(), 3, this.geovisitas)
    .pipe(
      map( res => res.geovisitas.map( geovisita => ({
        ...geovisita,
        /* mes: (+geovisita.mes),
        year: (+geovisita.year),
        sesiones: (+geovisita.sesiones) */

      })) )
    )
  }

  initMetricasListener(){
    //return this.googlesheets.get<Metricas>(this._datos.getGoogleSheetId(), 4, this.metricas)
    return this.HTTP.get<any>('https://script.google.com/macros/s/AKfycbzFGiXcykFV0Aa3ypyZyOYLmoGWCiMPuZekp6yGZZ7xjXkvVEi_HmVV6WIjsZYGIs7S/exec')
    .pipe(
      tap(res => { console.log(res) }),
      map(res => res.metricas.map( metrica => ({
        ...metrica,
        /* mes: (+metrica.mes),
        year: (+metrica.year),
        adquisicion: {
          adq_directa: (+metrica.adquisicion.adq_directa),
          adq_organica: (+metrica.adquisicion.adq_organica),
          adq_social: (+metrica.adquisicion.adq_social),
          adq_referral: (+metrica.adquisicion.adq_referral),
        },
        duracionmedia: (+metrica.duracionmedia),
        edad: {
          edadg1: parseFloat(metrica.edad.edadg1.replace(/,/, '.')),
          edadg2: parseFloat(metrica.edad.edadg2.replace(/,/, '.')),
          edadg3: parseFloat(metrica.edad.edadg3.replace(/,/, '.')),
          edadg4: parseFloat(metrica.edad.edadg4.replace(/,/, '.')),
          edadg5: parseFloat(metrica.edad.edadg5.replace(/,/, '.')),
          edadg6: parseFloat(metrica.edad.edadg6.replace(/,/, '.')),
        },
        genero: {
          h: parseFloat(metrica.genero.h.replace(/,/, '.')),
          m: parseFloat(metrica.genero.m.replace(/,/, '.')),
        },
        tecnologia: {
          tecno_escritorio: parseFloat(metrica.tecnologia.tecno_escritorio.replace(/,/, '.')),
          tecno_movil: parseFloat(metrica.tecnologia.tecno_movil.replace(/,/, '.')),
          tecno_tablet: parseFloat(metrica.tecnologia.tecno_tablet.replace(/,/, '.')),
      },
      usuarios_analytics: (+metrica.usuarios_analytics),
      usuarioswp: (+metrica.usuarioswp),
      nuevas_sesiones: parseFloat(metrica.nuevas_sesiones.replace(/,/, '.')),
      rebote: parseFloat(metrica.rebote.replace(/,/, '.')),
      sesiones: (+metrica.sesiones),
      visitas_paginas: (+metrica.visitas_paginas),
      reproducciones: (+metrica.reproducciones),
      facebook: (+metrica.facebook),
        twitter: (+metrica.twitter),
        instagram: (+metrica.instagram),
        linktree: (+metrica.linktree),
        youtube: (+metrica.youtube),
        articulos: (+metrica.articulos), */
      })))
    )
  }

  initRedesListener(){
    /* return this.googlesheets.get<Redes>(this._datos.getGoogleSheetId(), 7, this.redes).pipe(
      map( res => res.map( datoRedes => ({
        ...datoRedes,
        mes: (+datoRedes.mes),
        year: (+datoRedes.year),
        facebook: (+datoRedes.facebook),
        twitter: (+datoRedes.twitter),
        instagram: (+datoRedes.instagram),
        linktree: (+datoRedes.linktree),
        youtube: (+datoRedes.youtube),
        articulos: (+datoRedes.articulos),

      })) )
    ) */
  }
}
