import { createReducer, on } from '@ngrx/store';

import * as metricasActions from '../actions/metricas.actions';
import { Metricas } from '../metricas.model';

export interface State {
    metricas: Metricas[];
}

export const initialState: State = {
  metricas: []
}

const _metReducer = createReducer(initialState,

  on(metricasActions.cargaMet, (state, {metricas}) => ({ ...state, metricas: [ ...metricas ]})),
  on(metricasActions.limpiaMet, (state) => ({  ...state, metricas: []})),

);

export function metReducer(state, action) {
    return _metReducer(state, action);
}
