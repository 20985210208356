import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import { from, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState } from '../app.reducers';
import { Usuario } from '../models/usuario.model';
import * as peliculasActions from '../peliculas/actions/peliculas.actions';
import * as userActions from './actions/auth.actions';
import { limpiaRes } from '../shared/actions/resoluciones.actions';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userSubscription: Subscription;

  private usuariosValidos = [
    'alegreiff@gmail.com',
    'cristinarevaloyr@gmail.com',
    'gestion.infocce@canalcapital.gov.co',
    'yealexandrach@gmail.com',
    'programacionepr@gmail.com',
    'jaltruff@gmail.com',
    'retinalatinacine@gmail.com',
    'rhernandobg@gmail.com',
    //'virguez.ale@gmail.com',
    //'dianaguzmanrestrepo@gmail.com',
    //'ochoapablodv@gmail.com',
    'facundo.acau@gmail.com',
    'valentina.prego@mec.gub.uy',
    'difusion.icca@gmail.com',
    'inusuy@gmail.com',
    'observatorio@acau.gub.uy',
    'valentinaprietosoler.rl@gmail.com',
    'miguel.dobrich@acau.gub.uy',
    'gabriel.farias@acau.gub.uy',
    'stivenrv90@gmail.com',
    'cinebid.jaime@gmail.com',
    'cristinasmithway@gmail.com',
    'sebastian.amoroso@acau.gub.uy',
    'joaquin.maquieira@acau.gub.uy',
  ];
  private admin = ['alegreiff@gmail.com'];

  constructor(
    private afAuth: AngularFireAuth,
    private store: Store<AppState>,
    private db: AngularFirestore,
    private router: Router
  ) {}

  initAuthListener() {
    this.afAuth.authState.subscribe((fuser) => {
      //console.log ('QUIEN ERES InitAuthListener', fuser)
      if (fuser) {
        console.log('VERIFICA', fuser.email);

        if (!this.usuariosValidos.includes(fuser.email)) {
          console.log('Este usuario no está permitido');
          this.logout().then(() => {
            this.store.dispatch(peliculasActions.unSetPeliculas());
            this.store.dispatch(limpiaRes());
            this.router.navigate(['/acceso/login']);
            Swal.fire(
              'Usuario no admitido' + fuser.email,
              'Su dirección de correo no figura en nuestra lista de accesos',
              'success'
            );
          });
          return;
        }
        this.userSubscription = this.db
          .collection('usuarios')
          .doc(`${fuser.uid}`)
          .valueChanges()
          .subscribe((firestoreUser: any) => {
            if (firestoreUser) {
              console.log(' pasa por AUTH', firestoreUser);
            } else {
              const newUser = new Usuario(
                fuser.uid,
                fuser.displayName,
                fuser.email,
                false
              );
              return this.db
                .collection('usuarios')
                .doc(`${fuser.uid}`)
                .set({ ...newUser });
            }

            const user = Usuario.fromFirebase(firestoreUser);
            this.store.dispatch(userActions.setUser({ user }));
            //Swal.fire('Bienvenido', fuser.email, 'success')
          });
      } else {
        this.userSubscription?.unsubscribe();
        this.store.dispatch(userActions.unSetUser());

        console.log('UNSET PELICULAS?');
      }
    });
  }
  crearUsuario(nombre: string, email: string, password: string) {
    console.log(nombre, email, password);

    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then(({ user }) => {
        const newUser = new Usuario(user.uid, nombre, user.email);
        return this.db
          .collection('usuarios')
          .doc(`${user.uid}`)
          .set({ ...newUser });
      });
  }
  loginUsuario(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }
  logout() {
    return this.afAuth.signOut();
  }
  isAuth() {
    return this.afAuth.authState.pipe(map((fbUser) => fbUser != null));
  }

  GoogleAuth() {
    //return this.AuthLogin(new auth.GoogleAuthProvider());
    const provider = new firebase.auth.GoogleAuthProvider();
    //provider.setCustomParameters({ hd: 'canalcapital.gov.co'})

    return from(
      this.afAuth.setPersistence('session').then(() => {
        console.log('passa');
        return this.afAuth.signInWithPopup(provider).then((res) => {
          console.log('res', res);
        });
      })
    );
  }
}
