import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { GoogleSheetsDbService } from 'ng-google-sheets-db';
import { first, map, tap } from 'rxjs/operators';
import {
  Datopelicula,
  IngresoEgresoPeli,
  Pelicula,
} from '../models/pelicula.model';
import {
  AppStateConPeliculas,
  peliculaReducer,
} from './reducers/pelicula.reducer';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as peliculasCargadasActions from 'src/app/peliculas/locales/locales.actions';
import { CalendarEvent } from 'angular-calendar';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DataFilmesService {
  //gaurl = 'https://script.google.com/macros/s/AKfycbzAcBzQrbsNfgN1eTAlKq-cvToHxcF_-I-3sXLcVQJfpVpKDPW_gWtQ6pEm70oWEMtE/exec';
  //gaurl = 'https://script.google.com/macros/s/AKfycbztzXBkzgYd4kgV3BAa1fi1-UQY8rgw4935BkyUt0-bEJJeTgrDHX1dIxqyzSDG03g/exec';
  //gaurl =
    'https://script.google.com/macros/s/AKfycbztzXBkzgYd4kgV3BAa1fi1-UQY8rgw4935BkyUt0-bEJJeTgrDHX1dIxqyzSDG03g/exec';
  gaurl = 'https://script.google.com/macros/s/AKfycbxZmC2C0YhOEj427LinH-SnKBRZVKt7kNhUK8R8tOx6Bm9MK168RXeaApp3AZ0-iRrd/exec';
  private eventos: CalendarEvent[] = [];
  fechasEntradasSalidas = {
    peliculaId: 'PeliculaId',
    entrada: 'entrada',
    salida: 'salida',
  };

  /* hojaPeliculas = {
    id: 'ID',
    wpid: 'wpid',
    pais: 'pais',
    formato: 'formato',
    titulo: 'titulo',
    genero: 'genero',
    estreno: 'estreno',
    salida: 'salida',
    year: 'year',
    duracion: 'duracion',
    visitas: {
      2016: {
        2016_03: 'p2016_03',
        2016_04: 'p2016_04',
        2016_05: 'p2016_05',
        2016_06: 'p2016_06',
        2016_07: 'p2016_07',
        2016_08: 'p2016_08',
        2016_09: 'p2016_09',
        2016_10: 'p2016_10',
        2016_11: 'p2016_11',
        2016_12: 'p2016_12',
      },
      2017: {
        2017_01: 'p2017_01',
        2017_02: 'p2017_02',
        2017_03: 'p2017_03',
        2017_04: 'p2017_04',
        2017_05: 'p2017_05',
        2017_06: 'p2017_06',
        2017_07: 'p2017_07',
        2017_08: 'p2017_08',
        2017_09: 'p2017_09',
        2017_10: 'p2017_10',
        2017_11: 'p2017_11',
        2017_12: 'p2017_12',
      },
      2018: {
        2018_01: 'p2018_01',
        2018_02: 'p2018_02',
        2018_03: 'p2018_03',
        2018_04: 'p2018_04',
        2018_05: 'p2018_05',
        2018_06: 'p2018_06',
        2018_07: 'p2018_07',
        2018_08: 'p2018_08',
        2018_09: 'p2018_09',
        2018_10: 'p2018_10',
        2018_11: 'p2018_11',
        2018_12: 'p2018_12',
      },
      2019: {
        2019_01: 'p2019_01',
        2019_02: 'p2019_02',
        2019_03: 'p2019_03',
        2019_04: 'p2019_04',
        2019_05: 'p2019_05',
        2019_06: 'p2019_06',
        2019_07: 'p2019_07',
        2019_08: 'p2019_08',
        2019_09: 'p2019_09',
        2019_10: 'p2019_10',
        2019_11: 'p2019_11',
        2019_12: 'p2019_12',
      },
      2020: {
        2020_01: 'p2020_01',
        2020_02: 'p2020_02',
        2020_03: 'p2020_03',
        2020_04: 'p2020_04',
        2020_05: 'p2020_05',
        2020_06: 'p2020_06',
        2020_07: 'p2020_07',
        2020_08: 'p2020_08',
        2020_09: 'p2020_09',
        2020_10: 'p2020_10',
        2020_11: 'p2020_11',
        2020_12: 'p2020_12',
      },
      2021: {
        2021_01: 'p2021_01',
        2021_02: 'p2021_02',
        2021_03: 'p2021_03',
        2021_04: 'p2021_04',
        2021_05: 'p2021_05',
        2021_06: 'p2021_06',
        2021_07: 'p2021_07',
        2021_08: 'p2021_08',
        2021_09: 'p2021_09',
        2021_10: 'p2021_10',
        2021_11: 'p2021_11',
        2021_12: 'p2021_12',
      },
    },
  }; */

  private mes: Date;
  private inicialDate: Date;
  private IngresosEgresosPeliculas: IngresoEgresoPeli[] = [];
  private googlesheetId = '1FedixhGiIEx0a-A2DdxkuESj57AMVmxTF3UeWYRW_g4';

  private idRETINA = '1I7uMiFZbujJ6ZvPZLV0HnhSuri6-Q6kZpdGfG_L1a3A';
  constructor(
    private db: AngularFirestore,
    private googlesheets: GoogleSheetsDbService,
    private store: Store<AppStateConPeliculas>,
    private HTTP: HttpClient
  ) {
    this.mes = moment().subtract(1, 'month').startOf('month').toDate();
    this.inicialDate = moment('01 Apr 2016').toDate();
  }
  getGoogleSheetId() {
    return this.googlesheetId;
  }
  getGasApi() {
    return this.gaurl;
  }

  getMes() {
    return this.mes;
  }
  getInitialDate() {
    return this.inicialDate;
  }
  getZapatos() {
    return this.db.collection('zapatos').valueChanges();
  }
  getEventos() {
    return this.eventos;
  }

  fechaspublicacionListener() {
    //return this.googlesheets.get<IngresoEgresoPeli>(this.googlesheetId, 2, this.fechasEntradasSalidas)
    return this.HTTP.get<any>(
      'https://script.google.com/macros/s/AKfycbxr1oxHPratNzgevO__yHbwXd4iQDrxVjOkW8eyI0qaC_xVAhBy_zYsiU933DXjFmuu/exec'
    ).pipe(
      first(),
      tap((res) => {
        console.log('VIENENNNNNNNNNNNNNNNNN', res.fechas.length);
      }),
      map((res: any) =>
        res.fechas.map((ingresoEgreso) => ({
          peliculaId: +ingresoEgreso.peliculaId,
          entrada: moment(ingresoEgreso.entrada, 'YYYY-MM-DD').format(
            'DD/MM/YYYY'
          ),
          salida:
            ingresoEgreso.salida != ''
              ? moment(ingresoEgreso.salida, 'YYYY-MM-DD').format('DD/MM/YYYY')
              : null,
          diff:
            ingresoEgreso.salida === ''
              ? moment().diff(
                  moment(ingresoEgreso.entrada, 'YYYY-MM-DD'),
                  'days'
                )
              : moment(ingresoEgreso.salida, 'YYYY-MM-DD').diff(
                  moment(ingresoEgreso.entrada, 'YYYY-MM-DD'),
                  'days'
                ),
        }))
      )
    );
  }
  estart() {
    this.fechaspublicacionListener().subscribe((res) => {
      this.IngresosEgresosPeliculas = res;
    });
  }

  pruebaGSAPI() {
    const url =
      'https://script.google.com/macros/s/AKfycbyv6RbCTHsMiZTl73K1k6lPZU3Ye7HDR4Eloy6548P06NgWp4cKuqBtazyKSVT-guGW/exec';
    return this.HTTP.get<any>(url);
  }

  initPublicadasListener() {
    console.log('La sencilla lentitud');
    this.fechaspublicacionListener().subscribe((res) => {
      this.eventos = [];
      this.IngresosEgresosPeliculas = res;
      let prueba = _.countBy(this.IngresosEgresosPeliculas, 'peliculaId');
      var sorted = _.chain(prueba)
        .map(function (veces, pelicula) {
          return {
            pelicula,
            veces,
          };
        })
        .orderBy('veces', 'desc')
        .filter((dato) => dato.veces > 3)
        .value();
      console.log(sorted);

      console.log('Tamaño de...  ', this.IngresosEgresosPeliculas[0]);

      /*this.googlesheets
        .get<Datopelicula>(this.googlesheetId, 1, this.hojaPeliculas)*/

      this.HTTP.get<any>(this.gaurl)
        .pipe(
          tap((res) => console.log('pasaPASApasa', res)),
          map((res) =>
            res.datos.map((pelicula) => ({
              ...pelicula,
              year: pelicula.year,
              duracion: pelicula.duracion,
              wpid: pelicula.wpid,
              id: pelicula.id,
              visitas: pelicula.visitas,
              /* visitas: {
                2016: {
                  2016_03: +pelicula.visitas[2016][2016_03],
                  2016_04: +pelicula.visitas[2016][2016_04],
                  2016_05: +pelicula.visitas[2016][2016_05],
                  2016_06: +pelicula.visitas[2016][2016_06],
                  2016_07: +pelicula.visitas[2016][2016_07],
                  2016_08: +pelicula.visitas[2016][2016_08],
                  2016_09: +pelicula.visitas[2016][2016_09],
                  2016_10: +pelicula.visitas[2016][2016_10],
                  2016_11: +pelicula.visitas[2016][2016_11],
                  2016_12: +pelicula.visitas[2016][2016_12],
                },
                2017: {
                  2017_01: +pelicula.visitas[2017][201701],
                  2017_02: +pelicula.visitas[2017][201702],
                  2017_03: +pelicula.visitas[2017][201703],
                  2017_04: +pelicula.visitas[2017][201704],
                  2017_05: +pelicula.visitas[2017][201705],
                  2017_06: +pelicula.visitas[2017][201706],
                  2017_07: +pelicula.visitas[2017][201707],
                  2017_08: +pelicula.visitas[2017][201708],
                  2017_09: +pelicula.visitas[2017][201709],
                  2017_10: +pelicula.visitas[2017][201710],
                  2017_11: +pelicula.visitas[2017][201711],
                  2017_12: +pelicula.visitas[2017][201712],
                },
                2018: {
                  2018_01: +pelicula.visitas[2018][201801],
                  2018_02: +pelicula.visitas[2018][201802],
                  2018_03: +pelicula.visitas[2018][201803],
                  2018_04: +pelicula.visitas[2018][201804],
                  2018_05: +pelicula.visitas[2018][201805],
                  2018_06: +pelicula.visitas[2018][201806],
                  2018_07: +pelicula.visitas[2018][201807],
                  2018_08: +pelicula.visitas[2018][201808],
                  2018_09: +pelicula.visitas[2018][201809],
                  2018_10: +pelicula.visitas[2018][201810],
                  2018_11: +pelicula.visitas[2018][201811],
                  2018_12: +pelicula.visitas[2018][201812],
                },
                2019: {
                  2019_01: +pelicula.visitas[2019][201901],
                  2019_02: +pelicula.visitas[2019][201902],
                  2019_03: +pelicula.visitas[2019][201903],
                  2019_04: +pelicula.visitas[2019][201904],
                  2019_05: +pelicula.visitas[2019][201905],
                  2019_06: +pelicula.visitas[2019][201906],
                  2019_07: +pelicula.visitas[2019][201907],
                  2019_08: +pelicula.visitas[2019][201908],
                  2019_09: +pelicula.visitas[2019][201909],
                  2019_10: +pelicula.visitas[2019][201910],
                  2019_11: +pelicula.visitas[2019][201911],
                  2019_12: +pelicula.visitas[2019][201912],
                },
                2020: {
                  2020_01: +pelicula.visitas[2020][202001],
                  2020_02: +pelicula.visitas[2020][202002],
                  2020_03: +pelicula.visitas[2020][202003],
                  2020_04: +pelicula.visitas[2020][202004],
                  2020_05: +pelicula.visitas[2020][202005],
                  2020_06: +pelicula.visitas[2020][202006],
                  2020_07: +pelicula.visitas[2020][202007],
                  2020_08: +pelicula.visitas[2020][202008],
                  2020_09: +pelicula.visitas[2020][202009],
                  2020_10: +pelicula.visitas[2020][202010],
                  2020_11: +pelicula.visitas[2020][202011],
                  2020_12: +pelicula.visitas[2020][202012],
                },
                2021: {
                  2021_01: +pelicula.visitas[2021][202101],
                  2021_02: +pelicula.visitas[2021][202102],
                  2021_03: +pelicula.visitas[2021][202103],
                  2021_04: +pelicula.visitas[2021][202104],
                  2021_05: +pelicula.visitas[2021][202105],
                  2021_06: +pelicula.visitas[2021][202106],
                  2021_07: +pelicula.visitas[2021][202107],
                },
              }, */
              2016: this.suma(pelicula.visitas[2016]),
              2017: this.suma(pelicula.visitas[2017]),
              2018: this.suma(pelicula.visitas[2018]),
              2019: this.suma(pelicula.visitas[2019]),
              2020: this.suma(pelicula.visitas[2020]),
              2021: this.suma(pelicula.visitas[2021]),
              2022: this.suma(pelicula.visitas[2022]),
              2023: this.suma(pelicula.visitas[2023]),
              formato:
                pelicula.formato === 'Cortos' || pelicula.formato === 'cortos'
                  ? 'Cortometraje'
                  : pelicula.formato === 'largos' ||
                    pelicula.formato === 'Largos'
                  ? 'Largometraje'
                  : 'ERROR DE FORMATO ' + pelicula.id,
              total:
                this.suma(pelicula.visitas[2016]) +
                this.suma(pelicula.visitas[2017]) +
                this.suma(pelicula.visitas[2018]) +
                this.suma(pelicula.visitas[2019]) +
                this.suma(pelicula.visitas[2020]) +
                this.suma(pelicula.visitas[2021]) +
                this.suma(pelicula.visitas[2022]) +
                this.suma(pelicula.visitas[2023]),
              //estreno: moment(pelicula.estreno, 'DD/MM/YYYY').format('DD/MM/YYYY'),
              estreno: moment(pelicula.estreno).format('DD/MM/YYYY'),
              /* salida:
                pelicula.salida === ''
                  ? moment().add(1, 'days').format('DD/MM/YYYY')
                  : moment(pelicula.salida, 'DD/MM/YYYY').format('DD/MM/YYYY'), */
              salida:
                pelicula.salida === ''
                  ? moment().add(1, 'days').format('DD/MM/YYYY')
                  : moment(pelicula.salida).format('DD/MM/YYYY'),
              /* diff:
                pelicula.salida === ''
                  ? moment()
                      .add(1, 'days')
                      .diff(moment(pelicula.estreno, 'DD/MM/YYYY'), 'days')
                  : moment(pelicula.salida, 'DD/MM/YYYY').diff(
                      moment(pelicula.estreno, 'DD/MM/YYYY'),
                      'days'
                    ), */
              diff:
                pelicula.salida === ''
                  ? moment()
                      .add(1, 'days')
                      .diff(moment(pelicula.estreno), 'days')
                  : moment(pelicula.salida).diff(
                      moment(pelicula.estreno),
                      'days'
                    ),
              activa: pelicula.salida === '' ? true : false,
              apariciones: this.apariciones(+pelicula.id, pelicula.titulo),
            }))
          )

          /* tap ( res => {
          let batch = this.db.firestore.batch();
          for( const peli of res ){
            batch.set( this.db.collection('filmes').doc(this.db.createId()).ref,  peli)
          }
          batch.commit().then( () => {
            console.log ('TODOS kreados')
          } )
        }), */
          //map( res => res.map( pelicula => this.db.collection('filmes').add( pelicula ) ))
        )
        .subscribe((peliculas: Datopelicula[]) => {
          console.log(peliculas[5]);
          this.store.dispatch(
            peliculasCargadasActions.setPeliculas({ peliculas })
          );
          //console.log('EVENTOS', this.eventos)
        });
    });
  }
  apariciones(peliculaId: number, titulo: string) {
    let salida = [];
    const ingresos = this.IngresosEgresosPeliculas.filter(
      (item) => item.peliculaId === peliculaId
    );
    salida.push(ingresos);
    //console.log ('ingresss', ingresos)

    for (const accion of ingresos) {
      if (accion.entrada) {
        const eventoIngreso: CalendarEvent = {
          id: accion.peliculaId,
          title: titulo + ' INGRESO',
          start: moment(accion.entrada, 'DD/MM/YYYY').toDate(),
          color: { primary: 'green', secondary: '' },
          cssClass: 'INGRESO',
        };
        this.eventos.push(eventoIngreso);
      }
      if (accion.salida) {
        const eventoSalida: CalendarEvent = {
          id: accion.peliculaId,
          title: titulo + ' SALIDA',
          //start: null,
          start: moment(accion.salida, 'DD/MM/YYYY').toDate(),
          color: { primary: 'red', secondary: '' },
          cssClass: 'SALIDA',
        };
        this.eventos.push(eventoSalida);
      }
    }
    /*const evento: CalendarEvent = {
      title: titulo,
      start: moment(, 'YYYY-MM-DD').toDate(),
    }*/
    return salida;
  }
  suma(object) {
    let total = 0;
    for (var property in object) {
      total += +object[property];
    }
    return total;
  }

  initPeliculasListener() {
    return this.db
      .collection('peliculas')
      .snapshotChanges()
      .pipe(
        map((snapshot) =>
          snapshot.map((doc) => ({
            uid: doc.payload.doc.id,
            ...(doc.payload.doc.data() as any),
          }))
        )
      );
  }

  initFilmesListener() {
    return this.db
      .collection('filmes')
      .snapshotChanges()
      .pipe(
        map((snapshot) =>
          snapshot.map((doc) => ({
            uid: doc.payload.doc.id,
            ...(doc.payload.doc.data() as any),
          }))
        )
      );
  }

  creaPelicula(pelicula: Pelicula) {
    let savedpelicula = { ...pelicula };
    savedpelicula.fecha = new Date();
    //console.log(savedpelicula);

    return this.db.collection('peliculas').add(savedpelicula);
  }
  imagenPelicula(peliculaId: string, imagenUrl: string) {
    return this.db
      .collection('peliculas')
      .doc(peliculaId)
      .update({ imagen: imagenUrl });
  }
  imagenPelicula2(peliculaId: string, imagenUrl: string) {
    return this.db
      .collection('peliculas')
      .doc(peliculaId)
      .update({ mediainfo: imagenUrl });
  }

  borrarPelicula(uid: string) {
    return this.db.collection('peliculas').doc(uid).delete();
  }
}
