import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { map } from 'rxjs/operators';
import { Resoluciones } from '../models/pelicula.model';

@Injectable({
  providedIn: 'root'
})
export class ResolucionesService {

  constructor(
    private db: AngularFirestore,
    private res: Ng2ImgMaxService
    ) { }

  resizeImagen(file, ancho, alto){
    return this.res.resize([file], ancho, alto)
  }

  initResolucionesListener(){
    return this.db.collection('resoluciones')
    .snapshotChanges()
    .pipe(
      map( snapshot => snapshot.map( doc => ({
        uid: doc.payload.doc.id,
        ...doc.payload.doc.data() as any
      }))
      )
    )

  }
  guardaResolucion(resolucion: Resoluciones){
    let resol = resolucion
    resol.pixeles = resol.alto * resol.ancho;
    resol.describe = `${resol.ancho} X ${ resol.alto}`
    return this.db.collection('resoluciones').add(
      resol
    )
  }
}
