import { NgModule } from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {AccordionModule} from 'primeng/accordion';
import {MenubarModule} from 'primeng/menubar';
import {CardModule} from 'primeng/card';
import {TableModule} from 'primeng/table';
import {PasswordModule} from 'primeng/password';
import {ToastModule} from 'primeng/toast';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {DialogModule} from 'primeng/dialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {RippleModule} from 'primeng/ripple';
import {SplitButtonModule} from 'primeng/splitbutton';
import {CalendarModule} from 'primeng/calendar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {TabViewModule} from 'primeng/tabview';
import {ChartModule} from 'primeng/chart';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
/* import { BadgeModule } from "primeng/badge"; */

@NgModule({
  declarations: [],

  exports: [
    ButtonModule,
    InputTextModule,
    AccordionModule,
    MenubarModule,
    CardModule,
    TableModule,
    PasswordModule,
    ToastModule,
    DropdownModule,
    FileUploadModule,
    DialogModule,
    InputNumberModule,
    RippleModule,
    SplitButtonModule,
    CalendarModule,
    RadioButtonModule,
    TabViewModule,
    ChartModule,
    TriStateCheckboxModule
    /* BadgeModule */

  ]
})
export class PrimengModule { }
