import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { PrimeNGConfig } from 'primeng/api';
import { Store } from '@ngrx/store';
import { AppStateConPeliculas } from './peliculas/reducers/pelicula.reducer';
import { Subscription } from 'rxjs/internal/Subscription';
import { DataFilmesService } from './peliculas/data-filmes.service';
import { ResolucionesService } from './shared/resoluciones.service';
import { MetricasService } from './metricas/metricas.service';
import * as peliculasActions from 'src/app/peliculas/actions/peliculas.actions';
import * as metricasActions from './metricas/actions/metricas.actions'
import * as redesActions from './redes/actions/redes.actions'
import * as geovisitasActions from './metricas/actions/geovisitas.actions'
import { Geovisitas, Metricas, Redes } from './metricas/metricas.model';
import { Pelicula, Resoluciones } from './models/pelicula.model';
import * as resolucionesActions from 'src/app/shared/actions/resoluciones.actions';
import * as appsmetricActions from 'src/app/shared/actions/apps.actions'
import { concat, tap } from 'rxjs/operators';
import { AppsService } from './shared/apps.service';
import { AppMetrica } from './models/android-app-model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'filmes';

  peliculaSubscribe: Subscription;
  resolucionSubscribe: Subscription;
  publicadasSubscribe: Subscription;
  filmsSubscribe: Subscription;
  metricasSubscribe: Subscription;
  geovisitasSubscribe: Subscription;
  appssubscribe: Subscription;
  isAuth: boolean;

  constructor(
  private auth: AuthService,
  private config: PrimeNGConfig,
  private _dataFilmes: DataFilmesService,
    private store: Store<AppStateConPeliculas>,
    private _resoluciones: ResolucionesService,
    private _metricasService: MetricasService,
    private _auth: AuthService,
    private _apps: AppsService
  ) {
    this.auth.initAuthListener()
  }

  ngOnInit() {


    this.config.setTranslation({
      dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],
      dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],
      dayNamesMin: [ "D","L","M","X","J","V","S" ],
      monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],
      monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ],
      today: 'Hoy',
      clear: 'Borrar'
    })
    this._auth.isAuth().subscribe((res) => {
      this.isAuth = res;
      if(res){
        this.cargaContenidos();
      }
    })


 }

cargaContenidos(){
  this.geovisitasSubscribe = this._metricasService.initGeovisitasListener()
    .pipe()
    .subscribe( (geovisitas: Geovisitas[])=>{
      this.store.dispatch(geovisitasActions.cargaGeo( { geovisitas}))
    })

    this.appssubscribe = this._apps.cargaAll()
    .subscribe( (metricapps: AppMetrica[]) => {
      this.store.dispatch( appsmetricActions.cargaMetricasApps({ metricapps }) )
    } )



    this.metricasSubscribe = this._metricasService.initMetricasListener()
    .pipe(
      //tap( res => console.log (res))
    )
    .subscribe((metricas: Metricas[]) =>{
      this.store.dispatch(metricasActions.cargaMet( { metricas }))
    })
    /* this._metricasService.initRedesListener()
    .subscribe((redes: Redes[]) =>{
      this.store.dispatch(redesActions.cargaRedes( { redes }))
    }) */
    
    //console.log ( 'pasa por el init de main.component en PELICULAS')
    this.peliculaSubscribe=this._dataFilmes
    .initPeliculasListener()
    .pipe()
    .subscribe((peliculas: Pelicula[]) => {


      //console.log ("sucribe a peliculas")
      this.store.dispatch(peliculasActions.setPeliculas({ peliculas }));

    });


    this._dataFilmes.estart();
    this.resolucionSubscribe = this._resoluciones
    .initResolucionesListener()
    .subscribe((resoluciones: Resoluciones[])=>{
      //console.log('Subscribe Resolutions')
      this.store.dispatch(resolucionesActions.cargaRes( { resoluciones } ))
    })
    this._dataFilmes.initPublicadasListener();
    /* this._dataFilmes.pruebaGSAPI().subscribe(res => {
      console.log( res.datos )
    }) */
}

ngOnDestroy(){
  //console.log("Unsubscribe Películas y Resoluciones")
  this.peliculaSubscribe.unsubscribe();
  this.resolucionSubscribe.unsubscribe();
  this.geovisitasSubscribe.unsubscribe();
  this.metricasSubscribe.unsubscribe();
  this.appssubscribe.unsubscribe();
  //this.filmsSubscribe.unsubscribe();
}
}
