import { createReducer, on } from '@ngrx/store';
import { Redes  } from "../../metricas/metricas.model";
import * as redesActions from '../actions/redes.actions'

export interface State {
    redes: Redes[]; 
}

export const initialState: State = {
    redes: []
}

const _redesReducer = createReducer(initialState,
    on(redesActions.cargaRedes, (state, {redes})=> ({...state, redes: [ ...redes ]})),
    on(redesActions.limpiaRedes, (state) =>({
        ...state, redes: []
    }))
    
    )

export function redesReducer(state, action) {
    return _redesReducer(state, action)
}